<template>
  <section class="all-templates-view" id="work-flow-list">
    <el-row type="flex" align="middle" :class="getIsMobile ? 'mt-2' : ''">
      <el-col :span="24">
        <div class="inner-navbar">
          <el-row :gutter="30">
            <el-col :span="12">
              <div class="left-block">
                <div class="icon-block">
                  <div class="icon">
                    <img
                      src="@/assets/img/icons/menu/work-flow.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div class="icon-text">
                    <h3 class="table-head-text">Form Builder</h3>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col
              :xl="{ span: 12 }"
              :lg="{ span: 12 }"
              :md="{ span: 12 }"
              :sm="{ span: 24 }"
              :xs="{ span: 24 }"
            >
              <el-row>
                <el-col
                  :xl="{ span: 8, offset: 8 }"
                  :lg="{ span: 8, offset: 8 }"
                  :md="{ span: 8, offset: 8 }"
                  :sm="{ span: 12 }"
                  :xs="{ span: 12 }"
                >
                  <div class="right-block float-right">
                    <el-input
                      placeholder="Search"
                      v-model="search_string"
                      @input="searchString"
                      clearable
                      class="search_input float-right mr-2"
                    ></el-input>
                  </div>
                </el-col>
                <el-col
                  :xl="{ span: 8 }"
                  :lg="{ span: 8 }"
                  :md="{ span: 8 }"
                  :sm="{ span: 12 }"
                  :xs="{ span: 12 }"
                >
                  <div class="right-block float-right">
                    <el-button
                      v-if="checkPermissionByPermissionsName('addFormBuilder')"
                      plain
                      :style="getIsMobile ? 'width:130px' : 'width:170px;'"
                      class="create-btn px-4 ml-2 mb-1"
                      type="primary"
                      @click="goToAddTemplate"
                      >{{
                        getIsMobile ? "Add new" : "Add Form Builder"
                      }}</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <div class="inner-navbar-content">
          <div class="vue-data-table-default" v-loading="loading">
            <data-tables-server
              v-if="!getIsMobile"
              :data="allWorkflows"
              :total="total"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              @query-change="loadData"
              style="width: 100%"
            >
              <el-table-column id="name" width="50">
                <template id="category" slot-scope="scope">
                  <span v-if="scope.row.icon_type == 'DEFAULT'">
                    <img
                      v-if="scope.row.icon"
                      :src="
                        require('@/assets/img/template-icons/' + scope.row.icon)
                      "
                      alt="icon"
                      width="30"
                    />
                    <img
                      v-else
                      src="@/assets/img/template-icons/block-portfolio.svg"
                      alt="icons"
                      width="30"
                    />
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Name" min-width="200">
                <template slot-scope="scope">
                  <span class="workflow-name" v-on:click="onEdit(scope.row)"
                    style="word-break: break-word">{{
                    scope.row.name
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="action" width="120">
                <template slot-scope="scope">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      Actions
                      <i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <a @click="onViewSlots(scope.row)" style="border: none"
                        ><el-dropdown-item
                          icon="el-icon-viewslot-outline"
                          command="viewslot"
                          >View Schedule Slots</el-dropdown-item
                        ></a
                      > -->
                      <router-link :to="'/fbd/' + scope.row.code">
                        <el-dropdown-item icon="el-icon-view"
                          >View</el-dropdown-item
                        >
                      </router-link>
                      <a @click="onEdit(scope.row)" style="border: none"
                        ><el-dropdown-item
                          icon="el-icon-edit-outline"
                          command="edit"
                          >Edit</el-dropdown-item
                        ></a
                      >
                      <a @click="onDelete(scope.row)" style="border: none">
                        <el-dropdown-item icon="el-icon-delete" command="delete"
                          >Delete</el-dropdown-item
                        ></a
                      >
                      <a @click="onGetLink(scope.row)">
                        <el-dropdown-item icon="el-icon-link">
                          Get Link
                        </el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>

              <el-table-column label="Filled by" min-width="180">
                <template slot-scope="scope">
                  <span class="user-type">{{
                  getFilledBy(scope.row) || "-"
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Templates / Components"
                min-width="220"
                align="center"
              >
                <template slot-scope="scope">
                  {{ getTemplateComponentCounts(scope.row.steps) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Last modified"
                min-width="200"
                fixed="right"
              >
                <template slot-scope="scope" v-if="scope.row.updated_at">
                  <p class="text-grey-sm mb-0 mt-0">
                    {{ scope.row.updated_at | moment("MM-DD-YYYY") }}
                  </p>
                  <p class="text-grey-sm mb-0 mt-0">
                    {{ scope.row.updated_at | moment("HH:mm:ss") }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="Created at" min-width="200" >
                <template slot-scope="scope" v-if="scope.row.created_at">
                  <p class="text-grey-sm mb-0 mt-0">
                    {{ scope.row.created_at | moment("MM-DD-YYYY") }}
                  </p>
                  <p class="text-grey-sm mb-0 mt-0">
                    {{ scope.row.created_at | moment("HH:mm:ss") }}
                  </p>
                </template>
              </el-table-column>
            </data-tables-server>
            <data-tables-server
              :data="allWorkflows"
              :total="total"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :pagination-props="paginationProps"
              @query-change="loadData"
              style="width: 100%"
              v-else-if="getIsMobile"
              class="contacts-list-table"
            >
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">Owner:</div>
                    <div class="expand-column-item-content">
                      {{ scope.row.workflow_owner || "-" }}
                    </div>
                  </div>
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">
                      Templates / Components
                    </div>
                    <div class="expand-column-item-content">
                      {{ getTemplateComponentCounts(scope.row.steps) }}
                    </div>
                  </div>
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">Last Modified:</div>
                    <div class="expand-column-item-content">
                      <p class="text-grey-sm mb-0 mt-0">
                        {{ scope.row.updated_at | moment("MM-DD-YYYY") }}
                      </p>
                      <p class="text-grey-sm mb-0 mt-0">
                        {{ scope.row.updated_at | moment("HH:mm:ss") }}
                      </p>
                    </div>
                  </div>
                  <div class="expand-column-item">
                    <div class="expand-column-item-label">Created at:</div>
                    <div class="expand-column-item-content">
                      <p class="text-grey-sm mb-0 mt-0">
                        {{ scope.row.created_at | moment("MM-DD-YYYY") }}
                      </p>
                      <p class="text-grey-sm mb-0 mt-0">
                        {{ scope.row.created_at | moment("HH:mm:ss") }}
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Name" id="name" fixed="left">
                <template slot-scope="scope">
                  <span class="workflow-name" v-on:click="onEdit(scope.row)">{{
                    scope.row.name
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="action"
                label="Actions"
                class="action"
                align="right"
                fixed="right"
              >
                <template slot-scope="scope">
                  <ul class="action-buttons" style="flex-direction: column">
                    <li class="first-button">
                      <el-button
                        plain
                        size="mini"
                        type="primary"
                        class="px-2 ml-2"
                        title="View Form builder"
                        @click="goToFormbuilder(scope.row)"
                      >
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                    <li class="second-button">
                      <el-button
                        plain
                        size="mini"
                        type="primary"
                        class="px-2 ml-2"
                        title="Edit Form builder"
                        @click="onEdit(scope.row)"
                      >
                        <i class="el-icon-edit-outline"></i>
                      </el-button>
                    </li>
                    <li class="third-button">
                      <el-button
                        plain
                        size="mini"
                        type="primary"
                        class="px-2 ml-2"
                        title="Delete form builder"
                        @click="onDelete(scope.row)"
                      >
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </li>
                  </ul>
                </template>
              </el-table-column>
            </data-tables-server>
            <div v-else>No Form Builder</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <dialog-component :visible="getLinkPopup" @before-close="ClosegetLinkPopup" :title="getLinkFormBuilderName" :containerWidth="'30%'" :isShowFooter="false">
      <div class="formBuilder-GetLink">
      <h4>Web link</h4>
      <p>Send a direct link or paste the link in your website</p>
      <el-input v-model="link" :disabled="true"></el-input>
      <el-button style="float : right; margin-top: 10px; margin-bottom: 10px;" size="mini" type="primary" @click="copyURL"><i class="el-icon-copy-document"></i>Copy Link</el-button>
      </div>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { TimelineLite, Bounce, Elastic } from "gsap/dist/gsap";
import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("formBuilders", [
      "getAllFormBuildersData",
      "getFormBuilderDeleteStatus",
      "getFormBuilderDeleteError",
      "getFormBuilderPublicURLDetails"
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("roles", ["getRoleById", "getSuccess", "getRoleErrors"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 15, 20],
      };
    },
  },
  mixins: [PermissionsHelper],

 async mounted() {
  await  this.fetchAllFormBuilders();
    this.timeline = new TimelineLite({
      // onComplete: () => this.timeline.restart()
    });
    // this.animateStatSections();
  },

  data() {
    return {
      allWorkflows: [],
      currentPage: 1,
      limit: 5,
      pageSize: 10,
      total: 0,
      addBenefitVisible: false,
      updateBenefitVisible: false,
      selectedBenefit: null,
      loading: false,
      search_string: "",
      getLinkPopup : false,
      getLinkFormBuilderName : '',
      link : '',
    };
  },
  watch: {  
    async "$route.query.group"(group) {
      console.log(group);
      this.fetchAllFormBuilders();
    },
    async "$route.query.key"(key) {
      console.log("key",key);
      this.fetchAllFormBuilders();
    },
    async "$route.params.formbuilder_code"(){
      this.fetchAllFormBuilders();
    }
  },
  methods: {
    goToFormbuilder(data) {
      this.$router.push({ path: "/fbd/" + data.code });
    },
    async fetchAllFormBuilders() {
      this.loading = true;
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.$route.query.group && this.$route.query.group.length) {
        params.group = this.$route.query.group; 
        await this.$store.dispatch(
          "entityGroups/fetEntityGroupDataById",
          this.$route.query.group
        );
      }
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.activeRole) {
        await this.$store.dispatch(
          "roles/fetchRoleById",
          this.getAuthenticatedUser.activeRole.role_id
        );
        if (this.getRoleById && this.getRoleById) {
          params.groups = this.getRoleById.allowed_groups;
        }
      }
      await this.$store.dispatch("formBuilders/fetchAllFormBuilders", params);
      this.allWorkflows = [];
      if(this.getAllFormBuildersData?.data){
        this.total = this.getAllFormBuildersData.total;
        this.allWorkflows = this.getAllFormBuildersData.data; 
      }
      this.loading = false;
    },
    ClosegetLinkPopup(){
      this.getLinkPopup = false
    },
    searchString() {
      this.pageSize = 10;
      this.page = 1;
      this.fetchAllFormBuilders();
    },
    async loadData(data) {
      if ((data && data.type != "init") || !data) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllFormBuilders();
      }
    },

    async refreshFormbuilders() {
      this.pageSize = 10;
      this.currentPage = 1;
      await this.fetchAllFormBuilders();
    },

    goToAddTemplate() {
      this.$router.push({ path: "/formBuilders/add",query:this.$route.query});
    },
    async onDelete(formbuilder) {
      if (
        formbuilder &&
        formbuilder.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != formbuilder.created_by &&
        !this.checkPermissionByPermissionsName("deleteForms")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for deleting form.Please contact owner.",
        });
      } else {
        this.$confirm("Are you sure to delete the Form builder?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.deleteFormbuilder(formbuilder);
        });
      }
    },
    async deleteFormbuilder(formbuilder) {
      try {
        await this.$store.dispatch(
          "formBuilders/deleteFormbuilder",
          formbuilder._id
        );
        if (this.getFormBuilderDeleteStatus) {
          this.$notify.success({
            title: "Success",
            message: "Form builder deleted successfully",
          });
          this.refreshFormbuilders();
        } else {
          let message =
            this.getFormBuilderDeleteError &&
            this.getFormBuilderDeleteError.message
              ? this.getFormBuilderDeleteError.message
              : "Some went wrong on delete workflow";
          this.$notify.error({
            title: "Error",
            message: message,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    onEdit(form_builder_id) {
      if (
        form_builder_id &&
        form_builder_id.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != form_builder_id.created_by &&
        !this.checkPermissionByPermissionsName("editForms")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for editing form.Please contact owner.",
        });
      } else {
        console.log("checkPermissionByPermissionsName", form_builder_id);
        this.$router.push({
          name: "EditFormBuilders",
          params: { form_builder_id: form_builder_id._id },
        });
      }
    },

    onViewSlots(workflow) {
      this.$router.push({
        name: "ScheduleSlots",
        query: {
          workflow_id: workflow._id,
        },
      });
    },
    async onGetLink(formBuilder){
      this.loading = true
      if (formBuilder?.customization?.show_link_to_be_copy) {
        await this.$store.dispatch("formBuilders/fetchWorkFlowPublicURL", {
          formbuilder_details_id: formBuilder._id,
        });
        if(this.getFormBuilderPublicURLDetails.public_url){
          this.getLinkFormBuilderName = formBuilder.name
          this.link = this.getFormBuilderPublicURLDetails.public_url
          this.loading = false
          this.getLinkPopup = true
        }
      }
      else{
        this.$message.error("You do not have permission to get link")
        this.loading = false
      }
      
    },
    copyURL(){
      navigator.clipboard.writeText(this.link);
      this.$message({
        message: "Link copied successfully!",
        type: "success",
      });
    },
    getFilledBy(row){
      if (row.form_builders_owner === 'ENTITY') {
        return 'Application users';
      } else if (row.form_builders_owner === 'USER') {
        return 'Company users';
      } else if (row.form_builders_owner === 'COMPANY') {
        return 'Anyone';
      } else {
        return 'Anonymous';
      }
    },
    getTemplateComponentCounts(steps) {
      let templates = 0;
      let documents = 0;
      steps.forEach((element) => {
        if (element && element.type) {
          if (
            element.type == "COMPANY_DOCUMENTS" ||
            element.type == "OFFER_LETTER"
          ) {
            documents = documents + 1;
          } else {
            templates = templates + 1;
          }
        }
      });
      return templates + " / " + documents;
    },

    animateStatSections() {
      const {
        statOne,
        statOneIcon,
        statOneContent,
        statTwo,
        statTwoIcon,
        statTwoContent,
        statThree,
        statThreeIcon,
        statThreeContent,
      } = this.$refs;

      // this.timeline.to([statOne, statTwo, statThree], 0, {
      //   autoAlpha:0
      // })

      this.timeline
        .from(statOne, 1.5, {
          autoAlpha: 0,
          scale: 1.25,
          ease: Bounce.easeOut,
        })
        .from(
          statOneContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statOneIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statTwo,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statTwoContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statTwoIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );

      this.timeline
        .from(
          statThree,
          1.5,
          {
            autoAlpha: 0,
            scale: 1.25,
            ease: Bounce.easeOut,
          },
          "-=1"
        )
        .from(
          statThreeContent,
          1.5,
          {
            autoAlpha: 0,
            scale: 0,
            ease: Elastic.easeInOut,
          },
          "-=1.5"
        )
        .from(
          statThreeIcon,
          1.5,
          {
            opacity: 0,
            scale: 1.25,
            ease: Bounce.easeInOut,
          },
          "-=1"
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.formBuilder-GetLink{
  margin: 20px;
  margin-left: 0;
}
.workflow-name {
  cursor: pointer;
}
.workflows-list {
  width: 90%;
  display: table;
  margin: 0 auto;
  .search_input {
    height: 29px !important;
  }
  .add-button {
    height: 40px;
    border: 1.5px solid #285ed3;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0em 0.65em;
    color: #285ed3;
    &:hover {
      background-color: #285ed3;
      color: #ffffff;
      border-color: #285ed3;
    }
  }
  .inner-navbar-content {
    margin-top: 20px;
  }
  .guide-stats-block {
    padding: 1em 0 2em;
    .each-stat {
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      color: #ffffff;
      overflow: hidden;
      min-height: 105px;
      &.stat-one {
        background-color: #ff83a1;
      }
      &.stat-two {
        background-color: #5673dc;
      }
      &.stat-three {
        background-color: #ffbd12;
      }
      .icon {
        position: relative;
        z-index: 3;
        img {
          max-height: 50px;
        }
      }
      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
      .stat-content {
        position: relative;
        z-index: 2;
      }
      h3 {
        font-size: 3em;
        font-weight: 300;
        line-height: 1;
        text-align: center;
      }
      p {
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
      }
    }
    .usage-guide {
      position: relative;
      min-height: 105px;
      display: flex;
      background-color: #ebf0f3;
      border-radius: 8px;
      padding: 1em 1em 0.75em;
      overflow: hidden;
      .content {
        position: relative;
        z-index: 2;
      }
      .icon-right {
        width: 200px;
        position: relative;
        z-index: 3;
      }
      h4 {
        font-size: 1em;
        margin: 0;
        font-weight: 400;
      }
      p {
        font-size: 0.75em;
        line-height: 1.3;
        margin: 0.25em 0 0.75em;
      }
      .el-button {
        font-size: 0.85em;
        padding: 0.35em 0.75em;
        background-color: #6979f8;
        &:hover {
          background-color: #4558e9;
        }
      }

      .cover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: 1;
        img {
          width: 100%;
        }
      }
    }
  }
}
.contacts-list-table {
  .action-buttons {
    display: flex;
    flex-direction: row !important;
    & > li {
      margin-right: 0px !important;
      margin-left: 0px !important;
      & > button {
        margin-right: 3px !important;
        margin-left: 0px !important;
      }
    }
    .first-button {
    }
    .second-button {
    }
    .third-button {
    }
  }
}
.user-type{
  color: #3366cc;
}
</style>
